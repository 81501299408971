import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        address: '',
        fridge: false,
        catering: false,
        message: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const baseUrl = process.env.NODE_ENV === 'production' 
            // ? process.env.REACT_APP_API_URL_PROD 
            // : process.env.REACT_APP_API_URL_DEV;
            const baseUrl = 'https://web.veat.se';
            const response = await axios.post(`${baseUrl}/api/contact/send-query`, formData); 
            if (response.status === 200) {
                alert('Your query has been sent!');
            } else {
                throw new Error('Failed to send query');
            }
        } catch (error) {
            console.error('Error sending the query:', error);
            alert(`There was an issue sending your message: ${error.message}`);
        }
    };

    return (
        <div className="contact-form">
            <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                <input type="text" name="company" placeholder="Company" value={formData.company} onChange={handleChange} required />
                
                {/* Mail and Address on the same row */}
                <div className="form-row">
                    <input type="email" name="email" placeholder="Mail" value={formData.email} onChange={handleChange} required />
                    <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
                </div>
                
                {/* Fridge and Catering checkboxes on the same row */}
                <div className="checkbox-row">
                    <label>
                        <input type="checkbox" name="fridge" checked={formData.fridge} onChange={handleChange} /> Fridge
                    </label>
                    <label>
                        <input type="checkbox" name="catering" checked={formData.catering} onChange={handleChange} /> Catering
                    </label>
                </div>

                <textarea name="message" placeholder="Tell us more!" value={formData.message} onChange={handleChange}></textarea>
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default ContactForm;
